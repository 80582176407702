import React from "react"

import { Seo } from "../seo/"

import "../sass/just-video.scss"
import { Icon } from "../Layout/Components"

const video = () => {
  return (
    <React.Fragment>
      <Seo title="insieme. in movimento." />
      <img className="video-background" src="/img/ecoverso.frecce-gruppo.svg" />
      <video className="video-player" width="100%" autoPlay={true} controls>
        <source src="/video/ecoverso.mp4" type="video/mp4"></source>
        Your browser does not support the video tag
      </video>
      <div className="main_grid">
        <header className="header">
          <div className="header__top">
            <div className="header__top__inner">
              <div className="header__top__home">
                <Icon name="logo" className="header__logo" svg />
              </div>
            </div>
          </div>
        </header>
      </div>
    </React.Fragment>
  )
}

export default video
